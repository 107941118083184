import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(dateString: string) {
  return new Date(dateString).toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function findRelatedJobs(
  jobTitle = "",
  allJobs: string[] = [],
  limit = 5,
) {
  const flatJobs = Object.values(allJobs).flat();
  // 将输入的职位标题转换为小写并分词
  const inputWords = jobTitle.toLowerCase().split(" ");
  // 常见的职级词
  const levels = [
    "assistant",
    "associate",
    "junior",
    "senior",
    "lead",
    "head",
    "chief",
    "director",
    "manager",
    "supervisor",
    "coordinator",
    "specialist",
    "executive",
  ];
  // 计算每个职位与输入职位的相关度分数
  const scoredJobs = flatJobs
    .filter((job) => job !== jobTitle) // 排除输入的职位本身
    .map((job) => {
      const jobWords = job.toLowerCase().split(" ");
      let score = 0;

      // 1. 词汇完全匹配分数 (权重最高)
      inputWords.forEach((word) => {
        if (jobWords.includes(word)) {
          score += 3;
        }
      });
      // 2. 词汇部分匹配分数
      inputWords.forEach((word) => {
        if (word.length > 3) {
          // 忽略太短的词以避免误匹配
          if (jobWords.some((w) => w.includes(word) || word.includes(w))) {
            score += 1;
          }
        }
      });
      // 3. 职级匹配分数
      const inputLevel = levels.find((level) =>
        jobTitle.toLowerCase().includes(level),
      );
      if (inputLevel && job.toLowerCase().includes(inputLevel)) {
        score += 2;
      }
      // 4. 长度相似度分数
      const lengthDiff = Math.abs(job.length - jobTitle.length);
      if (lengthDiff < 5) score += 1;
      // 5. 首字母匹配分数
      if (job[0] === jobTitle[0]) {
        score += 0.5;
      }
      return { job, score };
    })
    .sort((a, b) => {
      // 首先按分数排序
      const scoreDiff = b.score - a.score;
      if (scoreDiff !== 0) return scoreDiff;
      // 分数相同时，按字母顺序排序
      return a.job.localeCompare(b.job);
    })
    .slice(0, limit) // 限制返回数量
    .map((item) => item.job);

  return scoredJobs;
}
// 使用示例：
// const relatedJobs = findRelatedJobs("Software Engineer");
// console.log(relatedJobs);
